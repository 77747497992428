// HomePageEN.js
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Landing from './LandingDesktop/Landing';
import TextBlock from './TextBlock/TextBlock';
import TextBlockMobile from './mobile/TextBlockMobile/TextBlockMobile';
import TextBlockMobile2 from './mobile/TextBlockMobile2/TextBlockMobile2';
import HotelList from './mobile/HotelList/HotelList';
import HotelList2 from './mobile/HotelList2/HotelList2';
import { useParams } from 'react-router-dom';

const HomePageEN = ({ content }) => {
  const { lang } = useParams();
  const language = lang || 'en'; // Utilise l'anglais par défaut
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const homeContent = content?.home || {};
  const { landing = {}, textBlock = {}, other = {} } = homeContent;

  return (
    <div>
      <Helmet>
        <title>Chapitre Six Hotels Paris | A Unique Collection of Boutique Hotels</title>
        <meta name="description" content="Discover Chapitre Six Hotels Paris, a collection of boutique hotels reflecting the lifestyle and personality of its host. Each room is unique." />
      </Helmet>
      {landing && (
        <section className="landing-section section">
          <Landing content={landing} language={language} />
        </section>
      )}
      {textBlock && (
        <>
          {windowWidth > 1024 ? (
            <section className="section">
              <TextBlock language={language} />
            </section>
          ) : (
            <>
              <section className="section">
                <TextBlockMobile content={textBlock} language={language} />
              </section>
              <section className="section">
                <TextBlockMobile2 content={textBlock} language={language} />
              </section>
            </>
          )}
        </>
      )}
      {windowWidth <= 1024 && other && (
        <>
          <section className="section">
            <HotelList content={other} language={language} />
          </section>
          <section className="section">
            <HotelList2 content={other} language={language} />
          </section>
        </>
      )}
    </div>
  );
};

export default HomePageEN;
