import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import LandingSpotify from './LandingSpotify/LandingSpotify';
import LandingSpotifyMobile from './mobile/LandingSpotifyMobile/LandingSpotifyMobile';

const SpotifyEN = ({ content, language }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <title>Chapitre Six Hotels | Hotels from Here and Beyond</title>
        <meta name="description" content="Every day, we bring stories to life in our hotels. Like in a family home, there’s always something happening. We are happy to share this joyful life with you." />
      </Helmet>
      <section className="landing-section section">
        {windowWidth > 1024 ? (
          <LandingSpotify content={content} language={language} />
        ) : (
          <LandingSpotifyMobile language={language} />
        )}
      </section>
    </div>
  );
};

export default SpotifyEN;
