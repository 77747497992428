import React from 'react';
import './imagesection.css';
import BIGIMAGE from '../../../../assets/History/mobile/big.jpg';
import content from '../../../../content.json';

const ImageSection = ({ language }) => {
  return (
    <div className='historyImageSection'>
      <div className='textContainer'>
        <h2>{content.history[language]?.title1 || 'Titre par défaut'}</h2> {/* Titre par défaut */}
        <p className='text'>{content.history[language]?.text2 || 'Texte par défaut'}</p> {/* Texte par défaut */}
      </div>

      <img src={BIGIMAGE} alt="historique" />
      <p className='title'>{content.history[language]?.textmobile1 || 'Texte mobile par défaut'}</p> {/* Texte mobile par défaut */}
    </div>
  );
};

export default ImageSection;
