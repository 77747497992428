import React, { useState, useEffect } from 'react';
import CROIX from '../../assets/croix.png';
import './moduleresa.css';

// Liste des hôtels disponibles
const hotels = [
  { id: 22993, name: "Hotel Monsieur George Champs Elysees Paris" },
  { id: 28793, name: "Hotel HANA" },
  { id: 1287, name: "Monsieur Cadet" },
  { id: 19034, name: "Maison Saintonge" },
  { id: 347, name: "Monsieur Aristide" },
  { id: 5956, name: "Hotel des Academies et des Arts" },
  { id: 13829, name: "Cap d'Antibes Beach Hotel" },
  { id: 25266, name: "Hotel La Ponche" },
];

// Correspondance entre l'ID de l'hôtel et son code
const hotelCodes = {
  22993: "J272", // Hotel Monsieur George Champs Elysees Paris
  28793: "J7GU", // Hotel HANA
  1287: "2UGT", // Monsieur Cadet
  19034: "2Q2P", // Maison Saintonge
  347: "JR59", // Monsieur Aristide
  5956: "2M4G", // Hotel des Academies et des Arts
  13829: "JK7H", // Cap d'Antibes Beach Hotel
  25266: "JRU8", // Hotel La Ponche
};

const BookingModule = ({ onClose }) => {
  const [arrivalDate, setArrivalDate] = useState(null);
  const [departureDate, setDepartureDate] = useState(null);
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(0);
  const [promoCode, setPromoCode] = useState('');
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [showHotels, setShowHotels] = useState(false);
  const [showDates, setShowDates] = useState(false);

  // Initialisation des dates de calendrier
  const [calendarMonth, setCalendarMonth] = useState(new Date()); // Mois de départ et d'arrivée démarrent sur novembre
  const [formattedArrivalDate, setFormattedArrivalDate] = useState('');
  const [formattedDepartureDate, setFormattedDepartureDate] = useState('');

  useEffect(() => {
    // Lorsque la date d'arrivée change, on ajuste le mois de départ
    if (arrivalDate) {
      const arrivalDateObj = new Date(arrivalDate);
      setCalendarMonth(arrivalDateObj); // Le mois de départ doit être le même que celui d'arrivée

      // Si la date de départ est inférieure à la date d'arrivée, on réinitialise la date de départ
      if (departureDate && departureDate <= arrivalDateObj) {
        setDepartureDate(null);
        setFormattedDepartureDate('');
      }
    }
  }, [arrivalDate, departureDate]);

  const handleHotelClick = (hotelId) => {
    setSelectedHotel(hotelId);
    setShowHotels(false);  // Masquer la liste des hôtels après la sélection
  };

  const handleSubmit = () => {
    const hotel = hotels.find(h => h.id === selectedHotel);
    const hotelCode = hotelCodes[selectedHotel] || 'JRU8';

    // Fonction pour formater les dates correctement sans décalage horaire
    const formatDate = (date) => {
      const localDate = new Date(date);
      localDate.setHours(0, 0, 0, 0); // Fixe l'heure à minuit local sans changer le jour
      const year = localDate.getFullYear();
      const month = localDate.getMonth() + 1; // Les mois sont de 0 à 11
      const day = localDate.getDate();
      return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
    };

    // Formater les dates d'arrivée et de départ
    const formattedArrivalDateString = arrivalDate ? formatDate(arrivalDate) : '';
    const formattedDepartureDateString = departureDate ? formatDate(departureDate) : '';

    // Créer l'URL
    const url = `https://www.secure-hotel-booking.com/d-edge/${hotel.name.replace(/ /g, '-')}/${hotelCode}/fr-FR?selectedAdultCount=${adultCount}&selectedChildCount=${childCount}&arrivalDate=${formattedArrivalDateString}&departureDate=${formattedDepartureDateString}&promocode=${promoCode}`;

    // Rediriger l'utilisateur
    window.location.href = url;
  };

  const changeMonth = (direction) => {
    const newMonth = new Date(calendarMonth);
    newMonth.setMonth(calendarMonth.getMonth() + direction);
    setCalendarMonth(newMonth);
  };

  const generateCalendar = () => {
    const startOfMonth = new Date(calendarMonth.getFullYear(), calendarMonth.getMonth(), 1);
    const endOfMonth = new Date(calendarMonth.getFullYear(), calendarMonth.getMonth() + 1, 0);
    const daysInMonth = [];
    let currentDay = startOfMonth;

    while (currentDay <= endOfMonth) {
      daysInMonth.push(new Date(currentDay));
      currentDay.setDate(currentDay.getDate() + 1);
    }

    return daysInMonth;
  };

  const isDateInRange = (date) => {
    if (!arrivalDate || !departureDate) return false;
    return date >= arrivalDate && date <= departureDate;
  };

  const isPastDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Fixe l'heure à minuit pour comparer uniquement la date
    return date < today;
  };

  const isPastDateForDeparture = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Fixe l'heure à minuit pour comparer uniquement la date
    return date < today || (arrivalDate && date <= arrivalDate);  // Empêche la sélection de dates avant l'arrivée
  };


  const handleDateSelect = (date, isArrivalDate) => {
    const localDate = new Date(date);
    localDate.setHours(0, 0, 0, 0);  // Fixe l'heure à 00:00:00 pour éviter les décalages

    const day = localDate.getDate();
    const month = localDate.getMonth() + 1; // Les mois commencent à 0 en JavaScript
    const year = localDate.getFullYear();

    if (isArrivalDate) {
      setArrivalDate(localDate);
      setFormattedArrivalDate(`${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`);
      if (!departureDate) setShowDates(true);  // Si pas de date de départ, on garde le calendrier ouvert
    } else {
      setDepartureDate(localDate);
      setFormattedDepartureDate(`${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`);
      setShowDates(false); // Ferme le calendrier après avoir sélectionné les 2 dates
    }
  };

  const handleCalendarToggle = () => {
    setShowDates(!showDates);  // Ouvre ou ferme le calendrier
  };

  return (
    <div className="ultraBigContainerResa">
      <button className="closeButton" onClick={onClose}>
        <img src={CROIX} alt="Close" className='croixResa' />
      </button>
      <div className="booking-module">
        <h2>Réserver une chambre</h2>

        {/* Ligne hôtels */}
        <div className="ligneModule1">
          <h3 onClick={() => setShowHotels(!showHotels)}>
            Hôtels
          </h3>
          <div className="hotel-button" onClick={() => setShowHotels(!showHotels)}>
            {selectedHotel ? (
              <span className="selected-hotel">
                {hotels.find(hotel => hotel.id === selectedHotel)?.name}
              </span>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
                <path d="M1 1L8 11L11.5 6L15 1" stroke="black" strokeWidth="1.5" />
              </svg>
            )}
          </div>
        </div>

        {/* Liste des hôtels */}
        <div className={`hotel-list ${showHotels ? 'show' : ''}`}>
          {hotels.map((hotel) => (
            <button
              key={hotel.id}
              className={`hotel-button list-hotel-calendar ${hotel.id === selectedHotel ? 'selected' : ''}`}
              onClick={() => handleHotelClick(hotel.id)}
            >
              {hotel.name} <span className="voyager-text">Voyager</span>
            </button>
          ))}
        </div>

        {/* Ligne des dates */}
        <div className="ligneModule2">
          <div className='calenderModule'>
            <h3 onClick={handleCalendarToggle}>
              DATE
            </h3>

            <div className="conteneurSvg" onClick={handleCalendarToggle}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
                <path d="M1 1L8 11L11.5 6L15 1" stroke="black" strokeWidth="1.5" />
              </svg>
              <span className='resultDate'>{formattedArrivalDate} - {formattedDepartureDate}</span>
            </div>
          </div>

          {showDates && (
            <div className="date-picker-container">
              <div className="calendar-row">
                {/* Calendrier d'arrivée */}
                <div className="calendar-container">
                  <div className="calendar">
                    <div className='big-calendar-nav'>
                      <button className="calendar-nav" onClick={() => changeMonth(-1)}>❮</button>
                      <div className="calendar-month">
                        {calendarMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
                      </div>
                      <button className="calendar-nav" onClick={() => changeMonth(1)}>❯</button>
                    </div>

                    <div className="calendar-grid">
                      {['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'].map((day, index) => (
                        <div key={index} className="calendar-header">{day}</div>
                      ))}
                      {generateCalendar().map((date, index) => {
                        const isDisabled = isPastDate(date);  // Vérifie si la date est passée
                        const isSelected = date.toISOString().split('T')[0] === arrivalDate?.toISOString().split('T')[0];
                        return (
                          <button
                            key={index}
                            className={`calendar-day ${isSelected ? 'selected' : ''} 
                    ${isDateInRange(date) ? 'in-range' : ''} 
                    ${isDisabled ? 'disabled' : ''}`}
                            onClick={() => !isDisabled && handleDateSelect(date, true)} // Désactive le clic sur les jours passés
                            style={{ opacity: isDisabled ? 0.8 : 1 }} // Applique l'opacité de 80% si passé
                            disabled={isDisabled}  // Désactive le bouton si la date est passée
                          >
                            {date.getDate()}
                          </button>
                        );
                      })}
                    </div>

                  </div>
                </div>

                {/* Calendrier de départ */}
                <div className="calendar-container">
                  <div className="calendar">
                    <div className='big-calendar-nav'>
                      <button className="calendar-nav" onClick={() => changeMonth(-1)}>❮</button>
                      <div className="calendar-month">
                        {calendarMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
                      </div>
                      <button className="calendar-nav" onClick={() => changeMonth(1)}>❯</button>
                    </div>

                    <div className="calendar-grid">
                      {['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'].map((day, index) => (
                        <div key={index} className="calendar-header">{day}</div>
                      ))}
                      {generateCalendar().map((date, index) => {
                        const isDisabled = isPastDateForDeparture(date);  // Vérifie si la date est passée ou avant la date d'arrivée
                        const isSelected = date.toISOString().split('T')[0] === departureDate?.toISOString().split('T')[0];
                        return (
                          <button
                            key={index}
                            className={`calendar-day ${isSelected ? 'selected' : ''} 
                    ${isDateInRange(date) ? 'in-range' : ''} 
                    ${isDisabled ? 'disabled' : ''}`}
                            onClick={() => !isDisabled && handleDateSelect(date, false)} // Désactive le clic sur les jours passés
                            style={{ opacity: isDisabled ? 0.8 : 1 }} // Applique l'opacité de 80% si passé
                            disabled={isDisabled}  // Désactive le bouton si la date est passée
                          >
                            {date.getDate()}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Autres éléments */}
        <div className="ligneModule3">
          <h3>Nombre d'adultes</h3>
          <div className="form-group adulte">
            <input
              type="number"
              id="adultCount"
              value={adultCount}
              onChange={(e) => setAdultCount(Math.max(1, parseInt(e.target.value, 10)))}
              min="1"
              className="no-spinner"
            />
          </div>
        </div>

        <div className="ligneModule4">
          <h3>Nombre d'enfants</h3>
          <div className="form-group enfant">
            <input
              type="number"
              id="childCount"
              value={childCount}
              onChange={(e) => setChildCount(Math.max(0, parseInt(e.target.value, 10)))}
              min="0"
              className="no-spinner"
            />
          </div>
        </div>

        <div className="ligneModule5">
          <h3>Code promo</h3>
          <div className="form-group promo">
            <input
              type="text"
              id="promoCode"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
            />
          </div>
        </div>

        <button onClick={handleSubmit} className="submit-button">
          Voir les tarifs
        </button>
      </div>
    </div>
  );
};

export default BookingModule;
