import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import HistoryLanding from './HistoryLanding/HistoryLanding';
import Landing from './mobile/History/Landing/Landing';
import ImageSection from './mobile/History/ImageSection/ImageSection';
import SectionTexte from './mobile/History/SectionTexte/SectionTexte';

const HistoryPageEN = ({ content, language }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <link rel="icon" href="/public/favicon.png" />
        <title>Chapitre Six Hotels | Hotels and Stories</title>
        <meta name="description" content="To write and bring stories to life, to restore beautiful places, and to imagine unique atmospheres to welcome travelers: this is the purpose of Chapitre Six." />
      </Helmet>
      <section className="section">
        {windowWidth > 1024 ? (
          <HistoryLanding content={content} language={language} />
        ) : (
          <Landing content={content} language={language} />
        )}
      </section>

      {windowWidth <= 1024 && (
        <>
          <section className="section">
            <ImageSection content={content} language={language} />
          </section>

          <section className="section">
            <SectionTexte content={content} language={language} />
          </section>
        </>
      )}
    </div>
  );
};

export default HistoryPageEN;
