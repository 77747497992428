import React from 'react';
import './historylanding.css';
import content from '../../content.json';
import BIGIMG from '../../assets/History/big.gif';

const HistoryLanding = ({ language }) => {
  // Sécurisation de l'accès aux données de l'histoire
  const historyContent = content.history[language] || {};

  return (
    <div className='superContainer'>
      <div className='historyContainer'>
        <div className='leftPart'>
          <div className='textContainer'>
            <h1>{historyContent.title1 || 'Titre par défaut'}</h1>

            <div className='firstBlockText'>
              <p>{historyContent.text1 || 'Texte par défaut'}</p>
              <p>{historyContent.text2 || 'Texte par défaut'}</p>
            </div>
            <h2>{historyContent.title2 || 'Sous-titre par défaut'}</h2>

            <div className='secondBlockText'>
              <p>{historyContent.text3 || 'Texte par défaut'}</p>
              <p>{historyContent.text4 || 'Texte par défaut'}</p>
            </div>
          </div>
        </div>

        <div className='rightPart'>
          <img src={BIGIMG} alt="Big" />
        </div>
      </div>
    </div>
  );
};

export default HistoryLanding;
