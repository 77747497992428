import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './landingspotifymobile.css';

const playlists = [
    { src: "https://open.spotify.com/embed/playlist/7zBJcELDGLG1VOCT8tOG5Q?utm_source=generator", title: "Playlist 1" },
    { src: "https://open.spotify.com/embed/playlist/4P1VCkVQyZfdhRZE0pkrR0?utm_source=generator", title: "Playlist 2" },
    { src: "https://open.spotify.com/embed/playlist/17xwHe4r6YWbS0Cu7pm8PD?utm_source=generator", title: "Playlist 3" },
    { src: "https://open.spotify.com/embed/playlist/44VT9sDj6aPxPdvgtmrgz2?utm_source=generator", title: "Playlist 4" },
    { src: "https://open.spotify.com/embed/playlist/4fuoSGsXUc93gCG3CgAbhS?utm_source=generator", title: "Playlist 5" },
    { src: "https://open.spotify.com/embed/playlist/4fbULkvkNciMNLJRHv5edU?utm_source=generator", title: "Playlist 6" },
    { src: "https://open.spotify.com/embed/playlist/2Fvn4fJQk787asZfpRehCi?utm_source=generator", title: "Playlist 7" },
    { src: "https://open.spotify.com/embed/playlist/4Vm8VmSbjeY3hNWvenJm0u?utm_source=generator", title: "Playlist 8" },
];

// Flèche Gauche
const ArrowLeft = (props) => (
    <button className='slick-arrow slick-prev' {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
            <path d="M11 1L0.999999 8L6 11.5L11 15" stroke="black"/>
        </svg>
    </button>
);

// Flèche Droite
const ArrowRight = (props) => (
    <button className='slick-arrow slick-next' {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
            <path d="M1 15L11 8L6 4.5L0.999999 0.999999" stroke="black"/>
        </svg>
    </button>
);

const LandingSpotifyMobile = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 4000,
        nextArrow: <ArrowRight />,
        prevArrow: <ArrowLeft />,
        swipe: true, 
        touchThreshold: 10
    };

    return (
        <div className='superContainerMobileSpotify'>
            <h1>LA MUSIQUE</h1>
            <p className='subspotify'>Parce que chaque voyage crée un souvenir, parce qu’on se souvient d’une atmosphère, d’un parfum ou d’une chanson… revivez votre séjour en musique.</p>
            <Slider {...settings}>
                {playlists.map((playlist, index) => (
                    <div className='iframeContainer' key={index}>
                        <iframe
                            src={playlist.src}
                            title={playlist.title}
                            className='iframe'
                            width="100%"
                            height="450px"
                            frameBorder="0"
                            allow="encrypted-media"
                        ></iframe>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default LandingSpotifyMobile;

