import React, { useState, useEffect } from 'react';
import Booking from './ModuleResa/ModuleResa'; // Assurez-vous que le chemin est correct

const Test = ({ content, language }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="section">
      {/* Utilisation du module Booking avec rendu conditionnel en fonction de la largeur */}
      {windowWidth > 1024 ? (
        <Booking content={content} language={language} />
      ) : (
        <p>Version mobile du module Booking (si besoin d'un composant spécifique pour mobile)</p>
      )}
    </div>
  );
};

export default Test;
