import React from 'react';
import './sectiontexte.css';
import content from '../../../../content.json';
import SMALLIMAGE from '../../../../assets/History/mobile/small.jpg';

const SectionTexte = ({ language }) => {
  return (
    <div className='historySectionTexte'>
      <div className='textContainer'>
        <h1>{content.history[language]?.title2 || 'Titre par défaut'}</h1> {/* Titre par défaut */}
        <p>{content.history[language]?.textmobile2 || 'Texte mobile 2 par défaut'}</p> {/* Texte par défaut */}
        <p>{content.history[language]?.textmobile3 || 'Texte mobile 3 par défaut'}</p> {/* Texte par défaut */}
      </div>
      <div className='imgContainer'>
        <img src={SMALLIMAGE} alt="Description de l'image" />
      </div>
    </div>
  );
};

export default SectionTexte;
