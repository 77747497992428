import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './footer.css';
import content from '../../content.json';
import NAVPIC1 from '../../assets/Footer/PHOTO1.jpg';
import NAVPIC2 from '../../assets/Footer/PHOTO2.jpg';
import NAVPIC3 from '../../assets/Footer/PHOTO3.jpg';
import NAVPIC4 from '../../assets/Footer/PHOTO4.jpg';
import NAVPIC5 from '../../assets/Footer/PHOTO5.jpg';
import NAVPIC6 from '../../assets/Footer/PHOTO6.jpg';
import NAVPIC7 from '../../assets/Footer/PHOTO7.jpg';
import NAVPIC8 from '../../assets/Footer/PHOTO8.jpg';

const Footer = ({ changeLanguage, activeLanguage }) => {
  const [email, setEmail] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');

  const getFooterImages = () => {
    const path = window.location.pathname;

    if (path.includes('nos-actualites')) {
      return [NAVPIC5, NAVPIC6];
    } else if (path.includes('les-hotels')) {
      return [NAVPIC7, NAVPIC8];
    } else if (path.includes('histoire')) {
      return [NAVPIC3, NAVPIC4];
    } else {
      return [NAVPIC1, NAVPIC2];
    }
  };

  const images = getFooterImages();

  const handleLanguageChange = (lang) => {
    if (lang !== activeLanguage) {
      changeLanguage(lang); // Met à jour l'état de langue

      const currentPath = window.location.pathname.replace(/^(\/fr|\/en)/, ''); // Retire le préfixe de langue
      const newPath = lang === 'en' ? `/en${currentPath}` : currentPath; // Ajoute /en si la langue est anglaise

      // Redirige vers la nouvelle URL et recharge la page
      window.location.href = newPath; // Cela force le rafraîchissement de la page
    }
  };

  const handleSubscribe = () => {
    if (email) {
      setConfirmationMessage('Votre mail est bien enregistré');
      setEmail('');
    } else {
      setConfirmationMessage('Veuillez entrer un email valide');
    }
  };

  // Sécurisation de l'accès aux données du footer
  const footerContent = content.home[activeLanguage]?.footer || {};

  return (
    <div className='footerContainer'>
      <div className='imageContainer'>
        <img src={images[0]} alt="1" />
        <img src={images[1]} alt="2" />
      </div>
      <div className='footerContentContainer'>
        <div className='ligne1'>
          <Link to="https://careers.werecruit.io/fr/chapitre-six">{footerContent.recrutement}</Link>
          <div className='languefooter'>
            <button onClick={() => handleLanguageChange('fr')} className={activeLanguage === 'fr' ? 'active' : ''}>
              FR
            </button>
            <button onClick={() => handleLanguageChange('en')} className={activeLanguage === 'en' ? 'active' : ''}>
              EN
            </button>
          </div>
          <Link to='https://www.instagram.com/chapitresix_hotels'>{footerContent.instagram}</Link>
        </div>
        <div className='ligne2'>
          <Link to='mailto:contact@chapitresix.com'>{footerContent.contact}</Link>
          <Link to='https://linkedin.com/company/chapitre-six-hotels'>{footerContent.linkedin}</Link>
        </div>
        <div className='ligne3'>
          <p>{footerContent.mentions}</p>
          <div className='newsletter'>
            <p>{footerContent.newsletter}</p>
            <input
              type="email"
              name="sub-email"
              placeholder=""
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button onClick={handleSubscribe}>{footerContent.sub}</button>
          </div>
          {confirmationMessage && <p className='confirmation'>{confirmationMessage}</p>}
          <Link to='https://www.facebook.com/chapitresixhotels/'>{footerContent.facebook}</Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
