import React from 'react';
import '../TextBlockMobile/textblockmobile.css'
import SECTION2MOBILE from '../../../assets/Home/mobile/section2.jpg'
import content from '../../../content.json'
const TextBlockMobile = ({ language }) => {
  return (
    <div className='container section2LandingMobile'>
        <div className='textContainer'>
            <p className='title' >{content.home[language].textBlock.titremobile}</p>
            <p>{content.home[language].textBlock.textmobile1}</p>
        </div>
        <img className='imgMobile' src={SECTION2MOBILE} alt="" />
      
    </div>
  );
};

export default TextBlockMobile;
