import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './footermobile.css';
import content from '../../../content.json';
import NAVPIC1MOBILE from '../../../assets/Footer/Mobile/PHOTO1.jpg';
import NAVPIC2MOBILE from '../../../assets/Footer/Mobile/PHOTO2.jpg';
import NAVPIC3MOBILE from '../../../assets/Footer/Mobile/PHOTO3.jpg';
import NAVPIC4MOBILE from '../../../assets/Footer/Mobile/PHOTO4.jpg';

const FooterMobile = ({ changeLanguage, activeLanguage = 'fr' }) => {
  const [currentLanguage, setCurrentLanguage] = useState(activeLanguage);
  const [isNewsletterInputVisible, setNewsletterInputVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState(''); // Nouvel état pour le message de confirmation

  useEffect(() => {
    setCurrentLanguage(activeLanguage);
  }, [activeLanguage]);

  const getFooterImage = () => {
    const path = window.location.pathname;

    if (path.includes('nos-actualites')) {
      return NAVPIC3MOBILE;
    } else if (path.includes('les-hotels')) {
      return NAVPIC4MOBILE;
    } else if (path.includes('histoire')) {
      return NAVPIC2MOBILE;
    } else {
      return NAVPIC1MOBILE;
    }
  };

  const imageSrc = getFooterImage();

  const handleLanguageChange = (lang) => {
    setCurrentLanguage(lang);
    changeLanguage(lang);
  };

  const handleNewsletterClick = () => {
    setNewsletterInputVisible(true);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    // Logic to handle email submission (e.g., sending to an API)
    console.log("Email submitted:", email);
    setEmail(''); // Reset email input
    setNewsletterInputVisible(false); // Hide input after submission
    setConfirmationMessage('Votre mail est bien enregistré.'); // Afficher le message de confirmation
  };

  return (
    <div className='footerMobileBigContainer'>
      <div className='imageFooterMobileContainer'>
        <img className='imgFooterMobile' src={imageSrc} alt="footer" />
      </div>
      <div className='contentFooterMobileContainer'>
        <div className='ligne1'>
          {isNewsletterInputVisible ? (
            <form onSubmit={handleEmailSubmit}>
              <input
                type="email"
                name="sub-email"
                placeholder="Votre email"
                value={email}
                onChange={handleEmailChange}
                required
              />
              <button type="submit">OK</button>
            </form>
          ) : (
            <p onClick={handleNewsletterClick}>{content.home.fr.footer.newsletter}</p>
          )}
          <div className='langueMobileFooter'>
            <button
              className={`fr ${currentLanguage === 'fr' ? 'active' : ''}`}
              onClick={() => handleLanguageChange('fr')}
            >
              FR
            </button>
            <button
              className={`${currentLanguage === 'en' ? 'active' : ''}`} 
              onClick={() => handleLanguageChange('en')}
            >
              EN
            </button>
          </div>
        </div>

        {confirmationMessage && (
          <div className='confirmationMessage'>
            <p>{confirmationMessage}</p>
          </div>
        )}

        <div className='ligne2'>
          <Link to="https://careers.werecruit.io/fr/chapitre-six">{content.home[currentLanguage].footer.recrutement}</Link>
          <Link to='https://www.instagram.com/chapitresix_hotels'>{content.home[currentLanguage].footer.instagram}</Link>
        </div>

        <div className='ligne3'>
          <Link to='mailto:contact@chapitresix.com'>{content.home[currentLanguage].footer.contact}</Link>
          <Link to='https://linkedin.com/company/chapitre-six-hotels'>{content.home[currentLanguage].footer.linkedin}</Link>
        </div>

        <div className='ligne4'>
          <p>{content.home.fr.footer.mentions}</p>
          <Link to='https://www.facebook.com/chapitresixhotels/'>{content.home[currentLanguage].footer.facebook}</Link>
        </div>
      </div>
    </div>
  );
};

export default FooterMobile;
