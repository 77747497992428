import React from 'react';
import './landing.css';
import content from '../../../../content.json';
import GIF from '../../../../assets/History/mobile/gif.gif';

const HistoryLanding = ({ language }) => {
  // Sécurisation de l'accès au contenu
  const historyContent = content.history[language] || {};

  return (
    <div className='historyLandingMobile'>
      <h1>{historyContent.title1 || 'Titre par défaut'}</h1>
      <p>{historyContent.text1 || 'Texte par défaut'}</p>

      <img src={GIF} alt="Description du GIF" className='historyLandingGif' />
    </div>
  );
};

export default HistoryLanding;
