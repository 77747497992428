import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import HotelsLanding from './HotelsLanding/HotelsLanding';
import MobileLandingHotels from './mobile/MobileLandingHotels/MobileLandingHotels'; // Assure-toi que le chemin est correct

const HotelsEN = ({ content }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <title>Chapitre Six Hotels | A Collection of Beloved Houses</title>
        <meta name="description" content="Chapitre Six Hotels tells the art of hosting with elegance. Every day, stories are woven, adventures lived, reshaping the codes of the past into the present." />
      </Helmet>
      <section className="landing-section section">
        {windowWidth > 1024 ? (
          <HotelsLanding content={content} />
        ) : (
          <MobileLandingHotels content={content} />
        )}
      </section>
    </div>
  );
};

export default HotelsEN;
