import React from 'react';
import './mentions.css';
import content from '../../content.json';

const HistoryLanding = ({ language }) => {
  return (
    <div className='superContainerMentions'>
        <h1>{content.mentions[language].title1}</h1>
        <p>{content.mentions[language].text1}</p>
    </div>
  );
};

export default HistoryLanding;
