import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import LandingSpotify from './LandingSpotify/LandingSpotify';
import LandingSpotifyMobile from './mobile/LandingSpotifyMobile/LandingSpotifyMobile';

const Spotify = ({ content, language }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <title>Chapitre Six Hotels | Des Hotels d'ici et d'ailleurs</title>
        <meta name="description" content="Chaque jour, nous faisons vivre des histoires dans nos hôtels. Comme dans une maison de famille, il s’y passe toujours quelque chose. Nous sommes heureux de partager cette vie joyeuse avec vous." />
      </Helmet>
      <section className="landing-section section">
        {windowWidth > 1024 ? (
          <LandingSpotify content={content} language={language} />
        ) : (
          <LandingSpotifyMobile language={language} />
        )}
      </section>
    </div>
  );
};

export default Spotify;
